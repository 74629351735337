// 质检记录
<template>
  <div class="menu">
    <div class="screening">
      <el-dialog title="查询" v-model="theQueryDialog" :close-on-click-modal="false" :destroy-on-close="true"
        width="600px">
        <el-form label-position="left" label-width="100px" size="small" :model="searchFrom" ref="upform">
          <el-form-item label="主叫号码">
            <el-input v-model="searchFrom.telA" class="input-searchFrom-width" placeholder="请输入需要查询的手机号" size="mini">
            </el-input>
          </el-form-item>
          <el-form-item label="被叫号码">
            <el-input v-model="searchFrom.telB" class="input-searchFrom-width" placeholder="请输入需要查询的手机号" size="mini">
            </el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker v-model="valueTime" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期"
              clearable value-format="YYYY-MM-DD HH:mm:ss" size="mini" :default-time="defaultTime">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="命中关键词">
            <el-input v-model="searchFrom.keyword" class="input-searchFrom-width" placeholder="请输入需要查询的命中关键词"
              size="mini"></el-input>
          </el-form-item>
          <el-form-item label="质检类型">
            <div class="input-searchFrom-width">
              <el-select v-model="searchFrom.hitType" placeholder="请选择质检类型" style="width: 100%" clearable filterable>
                <el-option v-for="(item, index) in voiceName" :key="index" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="服务商">
            <el-select v-model="searchFrom.amountId" class="input-searchFrom-width" placeholder="请选择查询的服务商"
              style="width: 100%" clearable filterable @change="changeValuesamountId">
              <el-option v-for="(item, index) in amount_id" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="接口类型">
            <el-select v-model="searchFrom.interfaceId" class="input-searchFrom-width" placeholder="请选择查询的接口类型"
              style="width: 100%" clearable filterable>
              <el-option v-for="(item, index) in interface_id" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="通话时间">
            <div class="input-searchFrom-width">
              <el-select v-model="durationVal" placeholder="请选择通话时间" style="width: 100%" @change="changeValues"
                clearable filterable>
                <el-option v-for="(item, index) in [
                                    {
                                        name:'所有时间',
                                        val:0
                                    },{
                                        name:'0~10秒',
                                        val:1
                                    },{
                                        name:'10~60秒',
                                        val:2
                                    },{
                                        name:'60~90秒',
                                        val:3
                                    },{
                                        name:'90~120秒',
                                        val:4
                                    },{
                                        name:'大于120秒',
                                        val:5
                                    },
                                ]" :key="index" :label="item.name" :value="item.val">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flex-nowrap-flex-end">
              <el-button class="cancel" plain @click="reset" round>
                重置
              </el-button>
              <el-button class="confirmAdd" @click="onSubmit" round>
                查询
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <el-row>
      <el-col :span="12">
        <div class="title-name">
          数据列表
        </div>
      </el-col>
      <el-col :span="12">
        <div class="title-buttom">
          <div class="ml-10">
            <el-button icon="el-icon-search" class="button-el" size="medium" @click="theQueryDialog=true">查询
            </el-button>
          </div>
          <div class="ml-10">
            <el-tooltip content="刷新列表" placement="top" effect="light">
              <div class="font-refresh-out flex-nowrap-center-center" @click="reset(1)">
                <icon-font v-if="loading==false" type="icon-shuaxin" class="font-refresh" />
                <i v-else class="el-icon-loading loding-color"></i>
              </div>
            </el-tooltip>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div>
          <el-table class="eltable" v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark"
            style="width: 100%" :header-cell-style="{background:'#e0e5ff',color:'#24252F'}" row-key="id" size="mini"
            :row-style="{height:'50px'}" :cell-style="{padding:'0px'}" default-expand-all
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}" @selection-change="handleSelectionChange">
            <!-- <el-table-column
                        type="selection"
                        width="55">
                        </el-table-column> -->
            <el-table-column label="昵称" v-if="userType == 'system'">
              <template
                #default="scope">{{ scope.row.nickName == null|| scope.row.nickName == ''?'-': scope.row.nickName}}</template>
            </el-table-column>
            <el-table-column label="主叫号码">
              <template #default="scope">
                <span>{{ scope.row.telA == null|| scope.row.telA == ''?'-': scope.row.telA}}</span>
                <!-- <el-tooltip content="已被封禁" placement="top" effect="light">
                                <span class="ml-10 pointer"><i class="iconfont icon-fengjin no-red"></i></span>
                            </el-tooltip> -->
                <!-- <el-tooltip content="封禁用户" placement="top" effect="light"> -->
                <!-- <span class="ml-10 pointer"><i class="iconfont icon-jiejinxianshou yes-green"></i></span> -->
                <!-- <span @click="bannedUser(scope.row)" class="ml-10 pointer no-red">禁</span> -->
                <!-- </el-tooltip> -->
              </template>
            </el-table-column>
            <el-table-column label="企业号码">
              <template
                #default="scope">{{ scope.row.telX == null|| scope.row.telX == ''?'-': scope.row.telX}}</template>
            </el-table-column>
            <el-table-column label="被叫号码">
              <template
                #default="scope">{{ scope.row.telB == null|| scope.row.telB == ''?'-': scope.row.telB}}</template>
            </el-table-column>
            <el-table-column label="状态">
              <template
                #default="scope">{{ scope.row.hitTypeStatus == null?'-':(scope.row.hitTypeStatus == 0?'未命中':'命中')}}</template>
            </el-table-column>
            <el-table-column label="命中类型">
              <template
                #default="scope">{{ scope.row.hitType == null|| scope.row.hitType == ''?'-': scope.row.hitType}}</template>
            </el-table-column>
            <el-table-column label="小号所属行业">
              <template #default="scope">{{ getBusinessTypeText(scope.row.businessType)}}</template>
            </el-table-column>
            <el-table-column label="识别结果">
              <template
                #default="scope">{{ scope.row.translatedResult == null?'-':(scope.row.translatedResult == 0?'成功':'失败')}}</template>
            </el-table-column>
            <el-table-column label="命中类型/关键字" width="180">
              <template #default="scope">
                <el-tooltip placement="top">
                  <template #content>
                    <p class="ellipsis">
                      <b>类型:</b> {{ scope.row.hitType == null|| scope.row.hitType == ''?'-': scope.row.hitType}}
                    </p>
                    <p class="ellipsis">
                      <b>关键字:</b>
                      {{ scope.row.hitTypeDesc == null|| scope.row.hitTypeDesc == ''?'-': scope.row.hitTypeDesc}}
                    </p>
                  </template>
                  <div>
                    <p class="ellipsis">
                      <b>类型:</b> {{ scope.row.hitType == null|| scope.row.hitType == ''?'-': scope.row.hitType}}
                    </p>
                    <p class="ellipsis">
                      <b>关键字:</b>
                      {{ scope.row.hitTypeDesc == null|| scope.row.hitTypeDesc == ''?'-': scope.row.hitTypeDesc}}
                    </p>
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="话术/语音内容">
              <template #default="scope">
                <el-popover placement="left" :width="500" trigger="hover" effect="dark">
                  <template #reference>
                    <p class="ellipsis-line3" @click="quickConfirm(scope.row)" v-html="scope.row.desc.join('')" />
                  </template>
                  <el-row class="voice-text">
                    <el-col :span="8" v-if="scope.row.hitTypeDesc && scope.row.hitTypeDesc !== '-'">
                      <el-tag size="small" effect="light" round v-for="(item, i) in scope.row.hitTypeDesc.split(',')"
                        :key="i" class="keywords" @click="scrollTo($event)">
                        {{ item }}
                      </el-tag>
                    </el-col>
                    <el-col :span="16">
                      <el-scrollbar @mouseenter="quickConfirm(scope.row)" class="voice-text-tooltip" max-height="300px">
                        <p v-for="(item, i) in scope.row.desc" :key="i">
                          <span class="sentence" v-html="item" />
                        </p>
                      </el-scrollbar>
                    </el-col>
                  </el-row>
                </el-popover>
                <p v-if="!scope.row.translatedContent">-</p>
              </template>
            </el-table-column>
            <el-table-column label="质检日期">
              <template
                #default="scope">{{ scope.row.createTime == null|| scope.row.createTime == ''?'-': scope.row.createTime}}</template>
            </el-table-column>
            <el-table-column label="对话时长">
              <template
                #default="scope">{{ scope.row.duration == null|| scope.row.duration == ''?'-': scope.row.duration}}</template>
            </el-table-column>
            <el-table-column label="对话">
              <template #default="scope">
                <el-tag :class="readId.indexOf(scope.row.id) == -1?'unRead':'Read'" class="talks"
                  @click="openDiv(scope.row)">
                  <icon-font type="icon-duihua" class="talkcolor" />
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <div class="pager">
      <div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          background :page-sizes="[10, 20, 30, 40]" :page-size="searchFrom.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <div>
      <el-dialog v-model="centerDialogVisible" title="对话详情" width="40%" :destroy-on-close="true">
        <div>
          <div class="hitType-box marigin-bottom-sm" style="font-size:16px;">{{ hitType == null?'':hitType }}
          </div>
          <div class="record">
            <audio class="recordSon" :src="recordUrl" controls="controls"></audio>
          </div>
          <div class="hitType-box-flex">
            <span>关键词：</span>
            <el-tag v-for="(item,index) in hitTypeDesc" :key="'hittag' + index">
              {{item}}
            </el-tag>
          </div>
          <div class="dialogue">
            <div v-for="(item,i) in dialogueList" :key="i" :class="item?.channel_id == 0?'item-left':'item-right'">
              <div>
                <div :class="item?.channel_id == 0?'title-left':'title-right'" class="title-item">
                  {{ item?.channel_id == 0?'主叫：':'被叫：' }}</div>
                <div class="item-text" :style="item?.channel_id == 0?'background: #fff;':'background: #67C23A;'"
                  v-html="$filter.mateKeyword(item?.text,hitTypeDesc)"></div>
              </div>
            </div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="centerDialogVisible = false">取消</el-button>
            <el-button type="primary" plain style="float:left;" @click="copyMessage">复制质检记录</el-button>
            <el-button type="primary" @click="centerDialogVisible = false">确认</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import voiceCheck from '@/api/open/voiceCheck/voiceCheck'
import voiceType from '@/api/open/voice/voiceType'
import interfacets from '@/api/open/privacy/interface'
import amountts from '@/api/open/privacy/amount'
import { getDate } from '@/utils/timeToChoose.js'
import { IconFont } from '@/utils/iconfont'
import { ElMessage } from 'element-plus'
export default {
  components: {
    IconFont,
  },
  data() {
    return {
      durationVal: 0,
      tableHeight: '',
      centerDialogVisible: false,
      searchFrom: {
        page: 1,
        pageSize: 10,
        telA: '',
        telB: '',
        amountId: '',
        interfaceId: '',
      },
      total: 0,
      currentPage: 1, //分页
      multipleSelection: [], //选中集合
      valueTime: '', //选中时间段
      defaultTime: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59),
      ], //默认时间 '12:00:00', '08:00:00'
      activeNames: [], //折叠面板
      tableData: [], //菜单表
      // loding 状态启用
      loading: true,
      dialogueList: [],
      recordUrl: '',
      hitTypeDesc: [],
      hitType: '',
      userType: '',
      theQueryDialog: false,
      voiceName: [],
      readId: [],
      readyToCopy: '',
      interface_id: [], // 拿到的接口类型
      amount_id: [], // 拿到的通信账户类型
    }
  },
  created() {
    this.tableHeight = this.$store.state.tableHeight
    this.userType = this.$store.state.userType
  },
  watch: {},
  mounted() {
    this.valueTime = [getDate(0) + ' 00:00:00', getDate(0) + ' 23:59:59']
    this.getDataList(true)
    this.getVoiceName()
    this.getAmountType()
  },
  methods: {
    // 滚动到指定质检关键字
    // 暂未实现同个关键字多处位置间的切换
    scrollTo(e) {
      this.$nextTick(() => {
        let list =
          e.currentTarget.parentElement.nextElementSibling.querySelectorAll(
            '.keyword-tag'
          )
        for (let i = 0; i < list.length; i++) {
          if (list[i].innerText === e.currentTarget.innerText) {
            list[i].scrollIntoView()
          }
        }
      })
    },
    // 将一条质检记录标记为已读(暂未刷新统计数)
    quickConfirm(row) {
      if (this.readId.indexOf(row.id) == -1) {
        this.readId.push(row.id)
      }
      if (row.read == 1) {
        return
      }
      {
        voiceCheck
          .esCheck({
            id: row.id,
          })
          .then((res) => {
            if (res.code == 200) {
              row.read = 1
            }
          })
      }
    },
    // 根据后端返回的语音文本json,获取其中的句子部分,并高亮显示关键字
    getVoiceText(item) {
      let keywords = item.hitTypeDesc
      if (item.translatedContent == null) {
        return ['-']
      }
      let sentences = []
      try {
        sentences = JSON.parse(item.translatedContent)?.payload?.sentences
      } catch (err) {
        // 因查看对话会改变translatedContent值,这里先通过捕获异常处理
        sentences = item.translatedContent
      }
      if (keywords !== '' && keywords != null) {
        // 如果包含关键字,对关键字做高亮处理
        keywords.split(',').forEach((keyword) => {
          sentences.map((sentence) => {
            if (sentence.text.search(keyword) !== -1) {
              sentence.text = sentence.text.replace(
                keyword,
                '<b class="keyword-tag" style="color:#ff0000;">' +
                  keyword +
                  '</b>'
              )
              return sentence
            }
          })
        })
      }
      if (sentences == undefined || sentences.length === 0) {
        return ['-']
      }
      return sentences.map((sentence) => sentence.text)
    },
    // 获取通信账户类型
    getAmountType() {
      amountts.getAmountList({}).then((res) => {
        this.amount_id = res.data
      })
      interfacets.seeApiList({}).then((res) => {
        this.interface_id = res.data
      })
    },
    // 获取通信账户类型
    getAmountType() {
      amountts.getAmountList({}).then((res) => {
        this.amount_id = res.data
      })
      interfacets.seeApiList({}).then((res) => {
        this.interface_id = res.data
      })
    },
    // 选中服务商
    changeValuesamountId(val) {
      this.searchFrom.interfaceId = ''
      interfacets
        .getInterface({
          amountId: val,
        })
        .then((res) => {
          this.interface_id = res.data
        })
    },
    copyMessage() {
      /**
				   * 用户：xxxx
				    主叫：xxxxx
				    小号：xxxx
				    被叫：xxxx
				    命中关键词：xxxx
				    录音地址：http://123.com//
				   */
      let input = document.createElement('textarea')
      input.style.opacity = '0'
      input.style.position = 'absolute'
      input.style.zIndex = '-1'
      document.body.append(input)
      input.value = this.readyToCopy
      // console.log(this.readyToCopy)
      input.select()
      let result = document.execCommand('Copy')
      if (result) {
        ElMessage({
          message: '复制成功',
          type: 'success',
        })
      } else {
        ElMessage({
          message: '复制失败',
          type: 'error',
        })
      }
      setTimeout(function () {
        document.body.removeChild(input)
      }, 50)
    },
    getBusinessTypeText(businessType) {
      switch (businessType) {
        case 0:
          return '非金融'
          break
        case 1:
          return '房产'
          break
        case 2:
          return '家装'
          break
        default:
          return '--'
      }
    },
    changeValues(val) {
      this.searchFrom.durationBegin = this.durationToVal(val)[0]
      this.searchFrom.durationEnd = this.durationToVal(val)[1]
    },
    durationToVal(val) {
      if (val == 0) {
        return []
      }
      if (val == 1) {
        return [0, 10]
      }
      if (val == 2) {
        return [10, 60]
      }
      if (val == 3) {
        return [60, 90]
      }
      if (val == 4) {
        return [90, 120]
      }
      if (val == 5) {
        return [120, '']
      }
    },
    // 查询
    onSubmit() {
      this.theQueryDialog = false
      this.getDataList(true)
    },
    // 重置
    reset(kind) {
      this.searchFrom = {
        page: this.currentPage,
        pageSize: 10,
      }
      this.valueTime = [getDate(0) + ' 00:00:00', getDate(0) + ' 23:59:59']
      if (kind == 1) {
        this.getDataList(true)
      }
    },
    // 语音质检类型下拉列表
    getVoiceName() {
      voiceType.voiceName({}).then((res) => {
        this.voiceName = res.data
      })
    },
    // 封禁用户
    bannedUser(row) {
      this.$confirm('将封禁该号码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // blackNumber.blackNumberDelete({
          //     ids:this.multipleSelection.length == 0?[row.id]:idList,
          // }).then((res) => {
          //     this.$notify({
          //         title: '成功',
          //         message: '号码删除成功！',
          //         type: 'success'
          //     });
          //     this.getDataList(true)
          // })
        })
        .catch(() => {
          // this.$notify.info({
          //     title: '通知',
          //     message: '已取消删除!'
          // });
        })
    },
    // 获取数据列表
    getDataList(type) {
      this.loading = type
      if (this.valueTime != null) {
        this.searchFrom.createTimeBegin = this.valueTime[0]
        this.searchFrom.createTimeEnd = this.valueTime[1]
      } else {
        this.searchFrom.createTimeBegin = ''
        this.searchFrom.createTimeEnd = ''
      }
      voiceCheck
        .esUserList({
          ...this.searchFrom,
        })
        .then((res) => {
          this.tableData = res.data.records
          this.tableData.forEach((item) => {
            item.desc = this.getVoiceText(item)
          })
          this.total = res.data.total
          this.loading = false
        })
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.searchFrom.pageSize = val
      this.getDataList(true)
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.searchFrom.page = val
      this.currentPage = val
      this.getDataList(true)
    },
    // 折叠面板
    handleChange(val) {
      console.log(val)
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 批量删除
    deleteData() {
      console.log(this.multipleSelection)
    },
    // 打开对话
    openDiv(row) {
      this.readyToCopy = `用户：${row.nickName || '--'}\r\n主叫：${
        row.telA || '--'
      }\r\n小号：${row.telX || '--'}\r\n被叫：${
        row.telB || '--'
      }\r\n通话时间：${row.stratTime || '--'}\r\n命中类型：${
        row.hitType || '--'
      }\r\n违规通知：检测到通话中出现违规词语，请贵公司在接到通知后2小时内核实并处理，如果未及时反馈给平台，平台将进行处罚\r\n录音地址：${
        row.recordUrl || '--'
      }`
      if (this.readId.indexOf(row.id) == -1) {
        this.readId.push(row.id)
      }
      if (row.hitTypeDesc != null) {
        this.hitTypeDesc = row.hitTypeDesc.split(',')
      }
      voiceCheck
        .getByCheckId({
          checkId: row.id,
        })
        .then((res) => {
          if (res.data?.translatedContent == null || res.data == null) {
            ElMessage({
              showClose: true,
              message: '暂无质检记录。',
              type: 'warning',
            })
          } else {
            this.recordUrl = row.recordUrl
            this.hitType = row.hitType
            this.dialogueList = JSON.parse(
              res.data.translatedContent
            ).payload?.sentences
            this.centerDialogVisible = true
          }
        })
      // this.recordUrl = row.recordUrl
      // this.hitType = row.hitType
      // if(row.hitTypeDesc != ''&&row.hitTypeDesc != null){
      //     this.hitTypeDesc = row.hitTypeDesc.split(",")
      // }else{
      //     this.hitTypeDesc = []
      // }
      // this.dialogueList = JSON.parse(row.translatedContent).payload?.sentences
      // this.centerDialogVisible = true
    },
  },
}
</script>

<style lang="scss" scoped>
.menu {
  height: 100%;
  overflow-y: overlay;
  padding-right: 1.125rem;

  .title-name {
    font-size: 0.9375rem;
    font-weight: 600;
    color: #303133;
  }

  .top-first {
    .search-box {
      width: 18.75rem;
    }
  }
}

.screening {
  margin-bottom: 0.9375rem;
}

.el-row {
  margin-bottom: 0.9375rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.editor {
  color: #0170ed;
  background: #e3f0ff;
}

.center-body {
  margin: 0 1.875rem;
}

.deletetable {
  color: #ff845b;
  background: #ffe4db;
}

.deletetable-other {
  margin-right: 1.25rem;
}

.confirmAdd {
  background: #637dff;
  color: #fff;
}

.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}

.screening-out {
  margin: 0 0.9375rem;
}

.out-icon:hover {
  background: #e3f0ff;
  color: #0170ed;
}

.pager {
  display: flex;
  justify-content: flex-end;
}

.pass {
  color: #67c23a;
}

.unpass {
  color: #f56c6c;
}

.priceColor {
  color: #409eff;
}

.talks {
  cursor: pointer;
}

.record {
  height: 5rem;
}

.dialogue {
  overflow-y: overlay;
  height: 25rem;
  padding: 0.425rem 1.5rem;
  background: #f5f5f5;
}

.recordSon {
  width: 100%;
}

.item-text {
  display: inline-block;
  padding: 0.375rem;
  border-radius: 0.375rem;
  color: #000;
}

.item-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.item-right {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.title-left {
  text-align: left;
}

.title-right {
  text-align: right;
}

.title-item {
  padding: 0.375rem;
}

.talkcolor {
  font-size: 1rem;
  color: #fff;
}

.Read {
  background: #abadbb;
}
</style>

<style lang="scss">
.menu .el-collapse-item__content {
  padding-bottom: 0 !important;
}

.eltable {
  overflow-y: overlay;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ellipsis-line3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
  cursor: pointer;
}
.voice-text {
  .voice-text-tooltip {
    font-size: 0.75rem;
  }
  .keywords {
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
  }
}
.sentence {
  background-color: #95ec69;
  border-radius: 5px;
  display: inline-block;
  padding: 5px 7px;
  color: #000000;
}
</style>
